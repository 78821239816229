import Modal from "../../../components/Modal/Modal";
import { useState } from "react";
import { StyledButton } from "../../../components/styled/styled-components";
import LoadingSpinner from "../../../components/LoadingSpinner/LoadingSpinner";
import { ISendPaymentLinkResponse, sendPaymentLink } from "../paymentMethod";
import { isEmailValid } from "./util";
import { AlertType } from "../../../interfaces/enums";
import { AlertContent } from "../../../interfaces/interfaces";

interface Props {
  orderId: number;
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
  setToast: React.Dispatch<React.SetStateAction<AlertContent | null>>;
}

function PaymentLinkModal({ orderId, setShowModal, setToast }: Props) {
  const [email, setEmail] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [showEmailInvalidError, setShowEmailInvalidError] =
    useState<boolean>(false);

  const handlePaymentLinkButton = async () => {
    if (!isEmailValid(email)) {
      return setShowEmailInvalidError(true);
    }
    setShowEmailInvalidError(false);
    setIsLoading(true);
    const res: ISendPaymentLinkResponse = await sendPaymentLink(email, orderId);
    if (res.status !== 200) {
      setToast({
        message: `Error to send payment, please try again.`,
        type: AlertType.Error,
      });
      setIsLoading(false);
    }

    setToast({
      message: `Link sent to ${email} successfully`,
      type: AlertType.Success,
    });
    setIsLoading(false);
    setShowModal(false);
  };

  return (
    <>
      <Modal>
        <div className="payment-modal">
          <div className="payment-modal-select">
            <h4>Send by Email</h4>
          </div>
          <div className="payment-modal-input">
            {showEmailInvalidError && (
              <span className="error-message">*Error, incorrect email.</span>
            )}
            <input
              type="text"
              placeholder="Enter the Email"
              className={showEmailInvalidError ? "error-input" : ""}
              disabled={isLoading}
              size={30}
              value={email}
              onChange={(event) => {
                setEmail(event.target.value);
              }}
            />
          </div>
          {/* Below part is commented out due to the SMS not being needed right now */}
          {/* <div className="payment-modal-select">
            <div className={cx(sendLink ? 'payment-modal-option-select' : '')} onClick={() => {setSendLink(true)}}>Send by Email</div>
            <div className={cx(sendLink ? '' : 'payment-modal-option-select')} onClick={() => {setSendLink(false)}}>Send By SMS</div>
          </div>
          {sendLink
          ?
          <div className="payment-modal-input">
            <input type="text" placeholder="Enter the Email" size={30} value={sendEmailLink} onChange={(event) => {setSendEmailLink(event.target.value)}} />
          </div>
          :
          <div className="payment-modal-input">
            <input type="text" placeholder="Enter the SMS" size={30} value={sendSMSLink} onChange={(event) => {setSendSMSLink(event.target.value)}} />
          </div>
          } */}
          {isLoading ? (
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <LoadingSpinner />
            </div>
          ) : (
            <div className="payment-modal-buttons">
              <StyledButton danger onClick={() => setShowModal(false)}>
                Cancel
              </StyledButton>
              <StyledButton
                success
                onClick={() => !isLoading && handlePaymentLinkButton()}
                disabled={isLoading}
              >
                Send Payment Link
              </StyledButton>
            </div>
          )}
        </div>
      </Modal>
    </>
  );
}

export default PaymentLinkModal;
