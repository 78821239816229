import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';
import '../../styles/Tooltip.scss'

type Props = {
  content: string,
  children?: JSX.Element
  theme?: string;
}
const Tooltip = ({ content, theme, children }: Props) => (
  <Tippy content={<span>{content}</span>} theme={theme}>
    <div>
      {children}
    </div>
  </Tippy>
)

export default Tooltip;
