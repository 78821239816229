import LoadingSpinner from "../../../../components/LoadingSpinner/LoadingSpinner";
import Tooltip from "../../../../components/Tooltip/Tooltip";
import { StyledButton } from "../../../../components/styled/styled-components";
import { AlertContent, OrderDetail } from "../../../../interfaces/interfaces";
import { AlertType } from "../../../../interfaces/enums";

export enum ResponseStatusTypes {
  "error",
  "success_unpaid",
  "success_paid",
  "success_confirming",
  "success_unavailable",
}

export const renderOrderStatus = (orderStatus: string) => {
  if (!orderStatus) return <LoadingSpinner />;

  try {
    return (
      <p className="order-status">
        {orderStatus.replace(/([A-Z])/g, " $1").replace(/^./, function (str) {
          return str.toUpperCase();
        })}
      </p>
    );
  } catch (err) {
    return <p className="order-status">orderStatus</p>;
  }
};

export const renderTrackingNumbers = (order: OrderDetail) => {
  if (order.track_numbers.length === 0) return <div>N/A</div>;

  return order.track_numbers.map((number: any) => {
    if (number.trackingNumber.includes("NOW COURIERS")) {
      const barcode = number.trackingNumber.substring(13);
      const trackingURL =
        "https://www.nowcouriers.co.nz/now/servlet/ITNG_TAndTServlet?page=1&VCCA=Enabled&Key_Type=CustomerLabel&customer_number=FASHBIZ&consignment_id=" +
        barcode;

      return (
        <div key={number.trackingNumber}>
          <a
            href={trackingURL}
            style={{ textDecoration: "underline" }}
            target="_blank"
            rel="noopener noreferrer"
          >
            Click here to track {number.trackingNumber}
          </a>
        </div>
      );
    } else {
      return (
        <div key={number.trackingNumber}>
          <a
            href={number.trackingUrl}
            style={{ textDecoration: "underline" }}
            target="_blank"
            rel="noopener noreferrer"
          >
            Click here to track {number.trackingNumber}
          </a>
        </div>
      );
    }
  });
};

export const renderPayment = (
  responseStatus: ResponseStatusTypes | null,
  paymentSessionUrl: string | null,
  isLoading: boolean,
  toast: AlertContent | null,
  setToast: React.Dispatch<React.SetStateAction<AlertContent | null>>
) => {
  if (responseStatus === ResponseStatusTypes.success_unpaid) {
    return (
      <div className="pay-order-button">
        <a href={paymentSessionUrl || ""}>
          <StyledButton success>Pay order</StyledButton>
        </a>
      </div>
    );
  }
  if (responseStatus === ResponseStatusTypes.success_paid) {
    return (
      <Tooltip content="Your order has been paid successfully">
        <span className="success-payment">Order Paid</span>
      </Tooltip>
    );
  }
  if (responseStatus === ResponseStatusTypes.success_confirming) {
    return (
      <Tooltip content="Your payment is being processed in our systems">
        <span className="success-confirming">Confirming</span>
      </Tooltip>
    );
  }
  if (responseStatus === ResponseStatusTypes.success_unavailable) {
    return <></>;
  }
  if (responseStatus === ResponseStatusTypes.error && !toast) {
    setToast({
      message: "Payment is currently unavailable",
      type: AlertType.Error,
    });
    return (
      <StyledButton info disabled>
        Pay Order
      </StyledButton>
    );
  }
  if (isLoading) return <LoadingSpinner />;

  return <span />;
};
