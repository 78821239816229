import { Link } from "react-router-dom";
import Badge from "../../../components/Badge/Badge";
import { ProductWithoutIndex } from "../../../interfaces/interfaces";
import { Brand } from "../../../interfaces/types";
import { SalesStatus } from "../../../interfaces/enums";

interface Props {
  product: ProductWithoutIndex;
}

const ProductCard = ({ product }: Props) => {
  const { brand, slug, code, name } = product;

  if (product) {
    return (
      <Link to={`/products/${brand}/${slug}`} className="product-card-wrapper">
        <div className="product-card-overlay" />
        {renderImage(product)}
        <div className="product-code-and-brand">
          <b>{code}</b>
          <span>{formatBrand(brand)}</span>
        </div>
        <small>{name}</small>
        {renderClearance(product)}
      </Link>
    );
  } else return <></>;
};

const renderImage = (product: ProductWithoutIndex) => {
  if (product.images.length === 0) {
    if (product.colors[0].images.length > 0) {
      return (
        <img
          style={{ width: "100%" }}
          src={product.colors[0].images[0].https_attachment_url_product}
          alt="product-color"
        />
      );
    } else {
      return (
        <img
          style={{ width: "100%" }}
          src="https://cdn.fashionbiz.com/Image+Not+Found.jpg"
          alt="not found"
        />
      );
    }
  } else {
    return (
      <img
        style={{ width: "100%" }}
        src={product.images[0].https_attachment_url_product}
        alt="product"
      />
    );
  }
};

// If we need custom banners for certain countries, we need to include "appBranch: subdomainTypes | ''" in the arguments for this function
const renderClearance = (product: ProductWithoutIndex) => {

  const sales_statuses = [SalesStatus.Clearance, SalesStatus.Discontinued]
  if ( product.slug.includes(SalesStatus.Clearance) || sales_statuses.includes(product.sales_status)) {
    return (
      <div className="double-badge">
        <Badge style={{ marginRight: "1em" }}>CLICK TO VIEW COLOURS</Badge>
        <Badge>NON-RETURNABLE</Badge>
      </div>
    );
  }

  if (product.sales_status !== SalesStatus.Normal) {
    return (
      <div>
        <Badge>{product.sales_status}</Badge>
      </div>
    );
  }

};

const formatBrand = (brand: Brand) => {
  switch (brand) {
    case "biz-collection":
      return "Biz Collection";
    case "biz-corporates":
      return "Biz Corporates";
    case "syzmik":
      return "Syzmik";
    case "biz-care":
      return "Biz Care";
    case "direct-group":
      return "Direct Group";
    default:
      return "";
  }
};

export default ProductCard;
