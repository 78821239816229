import { useContext, useEffect, useState } from "react";
import { OrderDetail } from "../../../../interfaces/interfaces";
import AuthenticationHOC from "../../../../components/AuthenticationHOC/AuthenticationHOC";
import { AlertContent } from "../../../../interfaces/interfaces";
import CartItemTable from "../../../../components/CartItemTable/CartItemTable";
import PDFDownloadButton from "../../../../components/PDFDownloadButton/PDFDownloadButton";
import Toast from "../../../../components/Toast/Toast";
import OrderHistoryPDF from "../../../../components/PDFTemplates/Templates/OrderHistoryPDF/OrderHistoryPDF";
import {
  Container,
  StyledButton,
  Wrapper,
} from "../../../../components/styled/styled-components";
import {
  IPaymentSessionResponse,
  getPaymentMethodSession,
} from "../../paymentMethod";
import PaymentLinkModal from "../PaymentLinkModal";
import { PaymentOption } from "../../../check-out/payment-selection/sendPaymentSelection";
import {
  getPaymentDetails,
  getPaymentDetailsPaid,
} from "../../../../utils/OrderPaymentDetails";
import {
  renderOrderStatus,
  renderPayment,
  renderTrackingNumbers,
  ResponseStatusTypes,
} from "./renders";
import { Context, IContext } from "../../../../context/ContextApp";
import LoadingSpinner from "../../../../components/LoadingSpinner/LoadingSpinner";
import {
  IUpdateBackorderResponse,
  updateBackorder,
} from "../../updateBackorder";
import BankTransferInfoModal from "../../../../components/PaymentInfo/BankTransferInfoModal";

interface Props {
  order: OrderDetail;
  generateAuthHeader: any;
}

function OrderHistoryDetailsAU({
  order: baseOrder,
  generateAuthHeader,
}: Props) {
  const [orderStatus, setOrderStatus] = useState("");
  const [paymentSessionUrl, setPaymentSessionUrl] = useState<string | null>(
    null
  );
  const [responseStatus, setResponseStatus] =
    useState<ResponseStatusTypes | null>(null);
  const [toast, setToast] = useState<AlertContent | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [order, setOrder] = useState<OrderDetail | null>(null);
  const [isBankTransferModalOpen, setIsBankTransferModalOpen] = useState(false);
  // Below part is for the sending of stripe link by SMS
  // const [sendLink, setSendLink] = useState<boolean>(true);
  // const [sendSMSLink, setSendSMSLink] = useState('');
  const { appBranch }: IContext = useContext(Context);
  const paymentDetails = order ? getPaymentDetails(order, appBranch) : [];
  const paymentDetailsPaid = order
    ? getPaymentDetailsPaid(order, appBranch)
    : [];

  const hasAmountToPay = order?.payment_details.total_products !== 0;
  const hasAmountPaid = order?.payment_details_paid.total_products !== 0;

  useEffect(() => {
    const loadOrderStatus = async () => {
      if (baseOrder.status.toLowerCase() === "invoiced") return setOrderStatus("Invoiced");

      const url = `${process.env.REACT_APP_HALFBACK_ROOT_API}/orders/order_status?order_accpac_no=${baseOrder.order_id}`;

      const res = await fetch(url, generateAuthHeader());
      const data = await res.json();

      try {
        if (data.status === "success") {
          setOrderStatus(data.result.status);
        } else {
          setOrderStatus("Submitted");
        }
      } catch (error) {
        // if (error.response.status === "401") {
        //   alert("Your session is expired. Please login again.");
        //   return <Redirect to="/" />;
        // }

        setOrderStatus("Submitted");
      }
    };
    const loadPaymentSession = async () => {
      const redirection_url = `${window.location.origin}/order-history`;
      const responseSession: IPaymentSessionResponse =
        await getPaymentMethodSession(baseOrder.id, redirection_url);
      if (
        responseSession.status === 200 &&
        responseSession.session_url !== null
      ) {
        setResponseStatus(ResponseStatusTypes.success_unpaid);
        setPaymentSessionUrl(responseSession.session_url);
        setOrder(responseSession.order || baseOrder);
        return;
      }
      if (
        responseSession.status === 200 &&
        responseSession.session_url === null
      ) {
        const paymentStatusAction = {
          unpaid: null,
          paid: ResponseStatusTypes.success_paid,
          unavailable: ResponseStatusTypes.success_unavailable,
          confirming: ResponseStatusTypes.success_confirming,
          default: ResponseStatusTypes.success_confirming,
        };
        const statusType =
          paymentStatusAction[responseSession.payment_status || "default"];

        setResponseStatus(statusType);
        setPaymentSessionUrl(null);
        setOrder(responseSession.order || baseOrder);
        return;
      }
      setResponseStatus(ResponseStatusTypes.error);
    };

    loadOrderStatus();
    if (!responseStatus) loadPaymentSession();
  }, [generateAuthHeader, responseStatus, baseOrder, order]);

  const reloadOrderItems = async () => {
    setIsLoading(true);

    const responseSession: IUpdateBackorderResponse = await updateBackorder(
      baseOrder.order_id || ""
    );

    if (responseSession.status === 201) {
      setOrder(responseSession.order || null);
    }
    setIsLoading(false);
  };

  if (!order || isLoading) return (
      <Wrapper style={{ padding: "10px" }}>
        <LoadingSpinner />
      </Wrapper>
    );

  return (
    <div className="store-order-detail">
      {isBankTransferModalOpen && (
        <BankTransferInfoModal
          onCloseModal={() => setIsBankTransferModalOpen(false)}
        />
      )}
      <div className="upper">
        <div className="upper-row">
          <table>
            <thead>
              <tr>
                <th>Order Status</th>
                <th>Tracking Info</th>
                {/* <th>Payment Method</th> */}
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{renderOrderStatus(orderStatus)}</td>
                <td>{renderTrackingNumbers(order)}</td>
                {/* <td>{renderPaymentMethod[order.payment_method]}</td> */}
              </tr>
            </tbody>
          </table>

          {showModal && (
            <PaymentLinkModal
              orderId={order.id}
              setShowModal={setShowModal}
              setToast={setToast}
            />
          )}

          <div className="group-buttons">
            <PDFDownloadButton
              document={<OrderHistoryPDF order={order} appBranch={appBranch} />}
            >
              <StyledButton>Print</StyledButton>
            </PDFDownloadButton>
            <div>
              {order.payment_method === PaymentOption.payOnline &&
                renderPayment(
                  responseStatus,
                  paymentSessionUrl,
                  isLoading,
                  toast,
                  setToast
                )}
              {order.payment_method === PaymentOption.BankTransfer && (
                <div className="pay-order-button">
                  <StyledButton
                    onClick={() => setIsBankTransferModalOpen(true)}
                  >
                    Bank Transfer Info
                  </StyledButton>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      <div className="lower">
        <div className="lower-row">
          {order.ship_to_address && (
            <div className="ship-to">
              <p className="title">Ship To:</p>
              <p>{order.ship_to_address}</p>
            </div>
          )}

          {order.comment && (
            <div className="comment">
              <p className="title">Comment</p>
              <p>{order.comment}</p>
            </div>
          )}
        </div>
        <div style={{ float: "right", margin: "1rem 0" }}>
          <StyledButton onClick={reloadOrderItems}>Reload Items</StyledButton>
        </div>
        <CartItemTable items={order.order_items} bo split checkout />
        <div className="order-costs">
          <div className="order-costs-row">
            {hasAmountPaid && (
              <Container className="order-costs-column">
                <h4>Amount Paid</h4>
                {paymentDetailsPaid.map((paymentDetail, index) => (
                  <Container key={index} row>
                    {paymentDetail.boldTitle ? (
                      <b>{paymentDetail.title}:</b>
                    ) : (
                      `${paymentDetail.title}:`
                    )}
                    <span>{paymentDetail.value}</span>
                  </Container>
                ))}
              </Container>
            )}

            {hasAmountToPay && (
              <Container className="order-costs-column">
                <h4>Amount to Pay</h4>
                {paymentDetails.map((paymentDetail, index) => (
                  <Container key={index} row>
                    {paymentDetail.boldTitle ? (
                      <b>{paymentDetail.title}:</b>
                    ) : (
                      `${paymentDetail.title}:`
                    )}
                    <span>{paymentDetail.value}</span>
                  </Container>
                ))}
              </Container>
            )}
          </div>
        </div>
        {
          // TODO: hide this for phase 2 of CC Payment
        }
        {order.payment_method === PaymentOption.payOnline && false && (
          <div className="payment-info">
            NOTE: The total for this order <u>excludes</u> any items on back
            order.
          </div>
        )}
      </div>
      <Toast content={toast} onClick={() => setToast(null)} />
    </div>
  );
}

export default AuthenticationHOC(OrderHistoryDetailsAU);
