import qs from "query-string";
import { useEffect, useState } from "react";
import { useLocation, useHistory } from "react-router";
import AuthenticationHOC from "../../components/AuthenticationHOC/AuthenticationHOC";
import LoadingSpinner from "../../components/LoadingSpinner/LoadingSpinner";
import SearchBar from "../../components/SearchBar/SearchBar";
import {
  Container,
  StyledButton,
  Wrapper,
} from "../../components/styled/styled-components";
import Toast from "../../components/Toast/Toast";
import { useDocumentTitle } from "../../hooks/document-title";
import { AlertType } from "../../interfaces/enums";
import { AlertContent, OrderDetail } from "../../interfaces/interfaces";
import OrderHistoryCard from "./OrderHistoryCard/OrderHistoryCard";
import getOrders from "./getOrders";
import SearchErrorCard from "../../components/SearchErrorCard/SearchErrorCard";
import { ErrorTypes } from "../../interfaces/enums";
import Paginator from "../../components/Paginator/Paginator";

const OrderHistory = () => {
  useDocumentTitle("Your order history");
  const [orders, setOrders] = useState<OrderDetail[]>([]);
  const [numberOfOrders, setNumberOfOrders] = useState<number>(0);
  const [isLoading, setIsLoading] = useState(false);
  const [toast, setToast] = useState<AlertContent | null>(null);
  const location = useLocation();
  const history = useHistory();
  const { page, ponumber, order: orderId } = qs.parse(location.search);

  useEffect(() => {
    const fetchOrders = async () => {
      setIsLoading(true);

      const ordersResponse = await getOrders({
        page,
        po_number: ponumber,
        order_id: orderId,
      });
      if (ordersResponse.status === 200) {
        setOrders(ordersResponse.orders || []);
        setNumberOfOrders(ordersResponse.total_count);
      }
      if (ordersResponse.status === 500) {
        setToast({
          message: ordersResponse.message,
          type: AlertType.Error,
        });
      }

      setIsLoading(false);
    };

    fetchOrders();
  }, [page, ponumber, orderId]);

  const renderSearchOrLink = () => {
    if (orderId !== undefined) {
      return (
        <div className={"order-history-button"}>
          <StyledButton onClick={() => history.push("/order-history")}>
            Back to Order History
          </StyledButton>
        </div>
      );
    }
    return (
      <SearchBar
        route="/order-history?ponumber"
        page={null}
        searchQuery="Search PO Number"
        value={ponumber}
      />
    );
  };

  return (
    <Container margin="1em 0">
      {isLoading ? (
        <Wrapper>
          <LoadingSpinner />
        </Wrapper>
      ) : (
        <>
          {renderSearchOrLink()}
          {orders.length === 0 ? (
            <div>
              <SearchErrorCard errorType={ErrorTypes.orderNotFound} userInput={ponumber} />
            </div>
          ) : (
            <>
              <Paginator
                numPages={Math.round(numberOfOrders / 10)}
                currentPage={page ?? 1}
              />
              <div className="order-history-wrapper">
                {orders.map((order, index) => (
                  <div key={index}>
                    <OrderHistoryCard order={order} open={index === 0} />
                  </div>
                ))}
              </div>
            </>
          )}
        </>
      )}
      <Toast content={toast} onClick={() => setToast(null)} />
    </Container>
  );
};

export default AuthenticationHOC(OrderHistory);
