import { generateAuthHeader } from "../../commonfunctions/authentication";
import { OrderDetail } from "../../interfaces/interfaces";

export type payment_status = "paid" | "confirming" | "unpaid" | "unavailable";

export interface IPaymentSessionResponse {
  status: number;
  session_url: string | null;
  message: string;
  payment_status?: payment_status | null;
  order?: OrderDetail | null;
}

export interface ISendPaymentLinkResponse {
  status: number;
  message: string;
}

export const getPaymentMethodSession = async (
  orderId: number,
  redirection_url: string
): Promise<IPaymentSessionResponse> => {
  const { headers } = generateAuthHeader();

  try {
    const res = await fetch(
      `${process.env.REACT_APP_HALFBACK_ROOT_API}/payment_sessions/create/${orderId}`,
      {
        method: "POST",
        body: JSON.stringify({ redirection_url }),
        headers: {
          "Content-Type": "application/json",
          ...headers,
        },
      }
    );
    const data: IPaymentSessionResponse = await res.json();
    return data;
  } catch (error) {
    const errorMessage: IPaymentSessionResponse = {
      status: 500,
      message: "Can't connect to Halfback API",
      session_url: null,
      payment_status: null,
    };

    return errorMessage;
  }
};

export const sendPaymentLink = async (
  data: string,
  orderId: number
): Promise<ISendPaymentLinkResponse> => {
  const { headers } = generateAuthHeader();

  try {
    await fetch(
      `${process.env.REACT_APP_HALFBACK_ROOT_API}/orders/${orderId}/send_payment_link`,
      {
        method: "POST",
        body: JSON.stringify({ data, by_email: true }),
        headers: {
          "Content-Type": "application/json",
          ...headers,
        },
      }
    );
    return {
      status: 200,
      message: "ok",
    };
  } catch (error) {
    const errorMessage: ISendPaymentLinkResponse = {
      status: 500,
      message: "Can't connect to Halfback API",
    };

    return errorMessage;
  }
};
