import { generateAuthHeader } from "../../commonfunctions/authentication";
import { OrderDetail } from "../../interfaces/interfaces";


export interface IUpdateBackorderResponse {
  status: number;
  order?: OrderDetail | null;
}

export const updateBackorder = async (
  orderId: string
): Promise<IUpdateBackorderResponse> => {
  const { headers } = generateAuthHeader();

  try {
    const res = await fetch(
      `${process.env.REACT_APP_HALFBACK_ROOT_API}/orders/update_backorder?order_accpac_no=${orderId}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          ...headers,
        },
      }
    );
    const data: OrderDetail = await res.json();


    return {
      status: 201,
      order: data
    };
  } catch (error) {
    const errorMessage = {
      status: 500,
      order: null
    }

    return errorMessage;
  }
};

