import { generateAuthHeader } from "../../../commonfunctions/authentication";

export enum PaymentOption {
  payOnline = "online",
  BankTransfer = "bank_transfer",
  CreditLimit = "credit_limit",
}

export const renderPaymentMethod = {
  online: "Visa/Mastercard",
  bank_transfer: "Bank Transfer",
  credit_limit: "Credit Limit"
}

export const sendPaymentSelection = async (paymentMethod: PaymentOption) => {
  const { headers } = generateAuthHeader();
  try {
    const res = await fetch(
      `${process.env.REACT_APP_HALFBACK_ROOT_API}/orders/current_order/payment_method/`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          ...headers,
        },
        body:  JSON.stringify({payment_method: paymentMethod})
      }
    )
    if(res.status === 201) return {status: 201, message: "ok"}
    return {status: 422, message: "Error API"}

  } catch (error) {
    return {status: 422, message: error}
  }
}

export default sendPaymentSelection;
