import cx from "classnames";
import { useContext, useState } from "react";
import {
  ProductColor,
  ProductDetailResponse,
  ProductImage
} from "../../../interfaces/interfaces";
import { FetchStatus } from "../../../interfaces/types";
import LoadingSpinner from "../../LoadingSpinner/LoadingSpinner";
import { StyledButton } from "../../styled/styled-components";
import ColorImageZoom from "./ColorImageZoom/ColorImageZoom";
import Icon from "../../Icon/icon";
import Plane from "../../../assets/plane";
import Tooltip from "../../Tooltip/Tooltip";
import { Context, IContext } from "../../../context/ContextApp";

interface Props {
  product: ProductDetailResponse;
  currentSelectedColor: ProductColor;
  skuFetchStatus: FetchStatus;
  getStock: any;
}



const ColorSelector = (props: Props) => {
  const { currentSelectedColor, product, skuFetchStatus, getStock } = props;
  const { appBranch }: IContext = useContext(Context);
  const [currentColorImages, setCurrentColorImages] = useState<ProductImage[]>(
    []
  );

  return (
    <>
      {product.colors.map((color, index) => (
        <div className="colorTile" key={index}>
          <div
            onClick={() => {
              getStock(color);
            }}
            className={cx({
              colorSelector: "true",
              selected: color === currentSelectedColor,
              success:
                color === currentSelectedColor && skuFetchStatus === "success",
              fail: color === currentSelectedColor && skuFetchStatus === "fail"
            })}
          >
            {color === currentSelectedColor && (
              <div className="selected-item-status-icon">
                {skuFetchStatus !== null && (
                  <>
                    {skuFetchStatus === "loading" ? (
                      <LoadingSpinner height={16} />
                    ) : skuFetchStatus === "success" ? (
                      <Icon name="CheckCircle" />
                    ) : (
                      <Icon name="TimesCircle" />
                    )}
                  </>
                )}
              </div>
            )}
            <div style={{ position: "relative" }}>
              <img
                style={{ width: "100%" }}
                src={
                  color.images[0]
                    ? color.images[0].https_attachment_url_product
                    : "https://cdn.fashionbiz.com/Image+Not+Found.jpg"
                }
                alt="preview_image"
              />

             {color.enable_ship_from_au && appBranch ==="nz" &&
              <div className="color-selector-container">
                <Tooltip
                  content={"This item may have stock and shipped from Australia"} 
                  theme="blue"
                >
                <Plane className="plane-svg" />
                </Tooltip>
              </div> 
              }
              
            </div>

            <div className="colorName">{color.name}</div>
          </div>
          <StyledButton
            full
            style={{ lineHeight: "5px" }}
            onClick={() => setCurrentColorImages(color.images)}
          >
            <Icon name="SearchPlus" />
          </StyledButton>
        </div>
      ))}
      <ColorImageZoom
        currentColorImages={currentColorImages}
        setCurrentColorImages={setCurrentColorImages}
      />
    </>
  );
};

export default ColorSelector;
