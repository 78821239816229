import { useContext, useEffect, useState } from "react";
import { OrderDetail } from "../../../../interfaces/interfaces";
import AuthenticationHOC from "../../../../components/AuthenticationHOC/AuthenticationHOC";
import { AlertContent } from "../../../../interfaces/interfaces";
import CartItemTable from "../../../../components/CartItemTable/CartItemTable";
import LoadingSpinner from "../../../../components/LoadingSpinner/LoadingSpinner";
import PDFDownloadButton from "../../../../components/PDFDownloadButton/PDFDownloadButton";
import Toast from "../../../../components/Toast/Toast";
import OrderHistoryPDF from "../../../../components/PDFTemplates/Templates/OrderHistoryPDF/OrderHistoryPDF";
import {
  Container,
  StyledButton,
} from "../../../../components/styled/styled-components";
import {
  IPaymentSessionResponse,
  getPaymentMethodSession,
} from "../../paymentMethod";
import { getPaymentDetails } from "../../../../utils/OrderPaymentDetails";
import { AlertType } from "../../../../interfaces/enums";

import Tooltip from "../../../../components/Tooltip/Tooltip";
import PaymentLinkModal from "../PaymentLinkModal";
import { Context, IContext } from "../../../../context/ContextApp";
import { PaymentOption } from "../../../check-out/payment-selection/sendPaymentSelection";
import BankTransferInfoModal from "../../../../components/PaymentInfo/BankTransferInfoModal";

enum ResponseStatusTypes {
  "error",
  "success_unpaid",
  "success_paid",
  "success_confirming",
  "success_unavailable",
}

interface Props {
  order: OrderDetail;
  generateAuthHeader: any;
}

function OrderHistoryDetails({ order, generateAuthHeader }: Props) {
  const [orderStatus, setOrderStatus] = useState("");
  const [paymentSessionUrl, setPaymentSessionUrl] = useState<string | null>(
    null
  );
  const [responseStatus, setResponseStatus] =
    useState<ResponseStatusTypes | null>(null);
  const [toast, setToast] = useState<AlertContent | null>(null);
  const { appBranch }: IContext = useContext(Context);
  const [showModal, setShowModal] = useState<boolean>(false);
  const paymentDetails = order ? getPaymentDetails(order, appBranch) : [];
  const [isBankTransferModalOpen, setIsBankTransferModalOpen] = useState(false);

  useEffect(() => {
    const loadOrderStatus = async () => {
      if (order.status.toLowerCase() === "invoiced") return setOrderStatus("Invoiced");

      const url = `${process.env.REACT_APP_HALFBACK_ROOT_API}/orders/order_status?order_accpac_no=${order.order_id}`;

      const res = await fetch(url, generateAuthHeader());
      const data = await res.json();

      try {
        if (data.status === "success") {
          setOrderStatus(data.result.status);
        } else {
          setOrderStatus("Submitted");
        }
      } catch (error) {
        // if (error.response.status === "401") {
        //   alert("Your session is expired. Please login again.");
        //   return <Redirect to="/" />;
        // }

        setOrderStatus("Submitted");
      }
    };
    const loadPaymentSession = async () => {
      const redirection_url = `${window.location.origin}/order-history`;
      const responseSession: IPaymentSessionResponse =
        await getPaymentMethodSession(order.id, redirection_url);
      if (
        responseSession.status === 200 &&
        responseSession.session_url !== null
      ) {
        setResponseStatus(ResponseStatusTypes.success_unpaid);
        setPaymentSessionUrl(responseSession.session_url);
        return;
      }
      if (
        responseSession.status === 200 &&
        responseSession.session_url === null
      ) {
        const paymentStatusAction = {
          unpaid: null,
          paid: ResponseStatusTypes.success_paid,
          unavailable: ResponseStatusTypes.success_unavailable,
          confirming: ResponseStatusTypes.success_confirming,
          default: ResponseStatusTypes.success_confirming,
        };
        const statusType =
          paymentStatusAction[responseSession.payment_status || "default"];

        setResponseStatus(statusType);
        setPaymentSessionUrl(null);
        return;
      }
      setResponseStatus(ResponseStatusTypes.error);
    };

    loadOrderStatus();
    if (!responseStatus) loadPaymentSession();
  }, [
    generateAuthHeader,
    order.order_id,
    order.status,
    order.id,
    responseStatus,
  ]);

  function renderOrderStatus() {
    if (!orderStatus) return <LoadingSpinner />;

    try {
      return (
        <p className="order-status">
          {orderStatus.replace(/([A-Z])/g, " $1").replace(/^./, function (str) {
            return str.toUpperCase();
          })}
        </p>
      );
    } catch (err) {
      return <p className="order-status">orderStatus</p>;
    }
  }

  function renderTrackingNumbers() {
    if (order.track_numbers.length === 0) return <div>N/A</div>;

    return order.track_numbers.map((number: any) => {
      if (number.trackingNumber.includes("NOW COURIERS")) {
        const barcode = number.trackingNumber.substring(13);
        const trackingURL =
          "https://www.nowcouriers.co.nz/now/servlet/ITNG_TAndTServlet?page=1&VCCA=Enabled&Key_Type=CustomerLabel&customer_number=FASHBIZ&consignment_id=" +
          barcode;

        return (
          <div key={number.trackingNumber}>
            <a
              href={trackingURL}
              style={{ textDecoration: "underline" }}
              target="_blank"
              rel="noopener noreferrer"
            >
              Click here to track {number.trackingNumber}
            </a>
          </div>
        );
      } else {
        return (
          <div key={number.trackingNumber}>
            <a
              href={number.trackingUrl}
              style={{ textDecoration: "underline" }}
              target="_blank"
              rel="noopener noreferrer"
            >
              Click here to track {number.trackingNumber}
            </a>
          </div>
        );
      }
    });
  }

  const renderPayment = () => {
    if (responseStatus === ResponseStatusTypes.success_unpaid) {
      return (
        <a href={paymentSessionUrl || ""} target="_blank">
          <StyledButton success className="pay-order-button">Pay order</StyledButton>
        </a>
      );
    }
    if (responseStatus === ResponseStatusTypes.success_paid) {
      return (
        <Tooltip content="Your order has been paid successfully">
          <span className="success-payment">Order Paid</span>
        </Tooltip>
      );
    }
    if (responseStatus === ResponseStatusTypes.success_confirming) {
      return (
        <Tooltip content="Your payment is being processed in our systems">
          <span className="success-confirming">Confirming</span>
        </Tooltip>
      );
    }
    if (responseStatus === ResponseStatusTypes.success_unavailable) {
      return <></>;
    }
    if (responseStatus === ResponseStatusTypes.error && !toast) {
      setToast({
        message: "Payment is currently unavailable",
        type: AlertType.Error,
      });
      return (
        <StyledButton info disabled>
          Pay Order
        </StyledButton>
      );
    }
  };

  return (
    <div className="store-order-detail">
      {isBankTransferModalOpen && (
        <BankTransferInfoModal
          onCloseModal={() => setIsBankTransferModalOpen(false)}
        />
      )}
      <div className="upper">
        <div className="upper-row">
          <table>
            <thead>
              <tr>
                <th>Order Status</th>
                <th>Tracking Info</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{renderOrderStatus()}</td>
                <td>{renderTrackingNumbers()}</td>
              </tr>
            </tbody>
          </table>

          {showModal && (
            <PaymentLinkModal
              orderId={order.id}
              setShowModal={setShowModal}
              setToast={setToast}
            />
          )}

          <div className="group-buttons">
            <PDFDownloadButton
              document={<OrderHistoryPDF order={order} appBranch={appBranch} />}
            >
              <StyledButton>Print</StyledButton>
            </PDFDownloadButton>
            <div>{renderPayment()}</div>
            {order.payment_method === PaymentOption.BankTransfer && (
                <div className="pay-order-button">
                  <StyledButton
                    onClick={() => setIsBankTransferModalOpen(true)}
                  >
                    Bank Transfer Info
                  </StyledButton>
                </div>
              )}
          </div>
        </div>
      </div>

      <div className="lower">
        <div className="lower-row">
          {order.ship_to_address && (
            <div className="ship-to">
              <p className="title">Ship To:</p>
              <p>{order.ship_to_address}</p>
            </div>
          )}

          {order.comment && (
            <div className="comment">
              <p className="title">Comment</p>
              <p>{order.comment}</p>
            </div>
          )}
        </div>

        <CartItemTable items={order.order_items} bo split checkout />
        <div className="order-costs">
          <div className="order-costs-row">
            <Container className="order-costs-column-au">
              {paymentDetails.map((paymentDetail, index) => (
                <Container key={index} row>
                  {paymentDetail.boldTitle ? (
                    <b>{paymentDetail.title}:</b>
                  ) : (
                    `${paymentDetail.title}:`
                  )}
                  <span>{paymentDetail.value}</span>
                </Container>
              ))}
            </Container>
          </div>
        </div>
      </div>
      <Toast content={toast} onClick={() => setToast(null)} />
    </div>
  );
}

export default AuthenticationHOC(OrderHistoryDetails);
