import { getSubdomain } from "../../context/ContextApp";

const appBranch = getSubdomain();

type Country = {
  [key: string]: {
    name: string;
    prefix?: string;
    acc: string;
    email: string;
  };
}

const locationByCountry: Country = {
  au: { name: 'Fashion Biz (Aust) P/L', prefix: '032 057', acc: '118954', email: 'cod@fashbiz.com.au' },
  nz: { name: 'Fashion Biz Ltd', acc: '03-0187-0354246-090', email: 'accountsR@fashionbiz.co.nz' },
  ca: { name: '', prefix: '', acc: '', email: '' },
}

const BankTransferInfo = () => (
  <div className="paymentTableWrapper">
    {locationByCountry[appBranch] && (
      <><p>
        <strong>Bank Transfer Account</strong>
      </p>
        <br />
        <table className="paymentTable">
          <tbody>
            <tr>
              <th>Name:</th>
              <td>{locationByCountry[appBranch].name}</td>
            </tr>
            {appBranch === 'au' && <tr>
              <th>BSB:</th>
              <td>{locationByCountry[appBranch].prefix}</td>
            </tr>}
            <tr>
              <th>Acc:</th>
              <td>{locationByCountry[appBranch].acc}</td>
            </tr>
            <tr>
              <th>Email Remittance:</th>
              <td>
                <a href={`mailto:${locationByCountry[appBranch].email}`}>{locationByCountry[appBranch].email}</a>
              </td>
            </tr>
          </tbody>
        </table>
      </>)}
  </div>
);

export default BankTransferInfo
